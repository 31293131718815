export const ConferencePageHeadings = [
  [
    {
      title: "Conference",
      content: [
        {
          title: "About Conference",
          link: "/conference#about",
        },
        {
          title: "Program Committee",
          link: "/conference#comittee",
        },
      ],
    },
    {
      title: "Program",
      content: [
        {
          title: "Scientific Program",
          link: "/conference#about",
        },
        {
          title: "Speakers",
          link: "/conference#speakers",
        },
        {
          title: "Workshops",
          link: "/conference#workshops",
        },
      ],
    },
  ],
  [
    {
      title: "Call for Abstract",
      content: [
        {
          title: "Abstract Submission Instruction",
          link: "/conference#instruction",
        },
        {
          title: "Submission Deadline",
          link: "/conference#deadline",
        },
      ],
    },
    {
      title: "Registration",
      content: [
        {
          title: "Conference Registration",
          link: "/conference#registration",
        },
        {
          title: "Workshop Registration",
          link: "/conference#workshop-registration",
        },
      ],
    },
  ],
  [
    {
      title: "Awards and Special Issue",
      content: [
        {
          title: "Awards",
          link: "/conference#awards",
        },
        {
          title: "Special Issue",
          link: "/conference#special-issue",
        },
      ],
    },
    {
      title: "Sponsors and Venue",
      content: [
        { title: "Sponsors", link: "/conference#sponsors" },
        { title: "Venue", link: "/conference#venue" },
      ],
    },
  ],
];
