import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import CustomDropdownHeader from "../CustomDropdownHeader";

const HeaderItem = ({ title, link, dropdownHeadings }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <li className="nav-item">
      <Link
        className={`nav-link ${
          location.pathname.split("/")?.[1] === link.split("/")?.[1]
            ? "active"
            : ""
        } ${dropdownHeadings?.length > 0 ? "dropdown-toggle" : ""}`}
        to={link}
        onClick={() =>
          ReactGA.event({ category: "Header Menu", action: title })
        }
      >
        {t(title)}
      </Link>
      {dropdownHeadings?.length > 0 ? (
        <CustomDropdownHeader dropdownHeadings={dropdownHeadings} />
      ) : null}
    </li>
  );
};

export default HeaderItem;
