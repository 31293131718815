import React from "react";
import AnnouncementBar from "../../../layout/announcement/AnnouncementBar";
import { HeaderLinks } from "./HeaderLinks/index.jsx";

import AnnouncementData from "../../../data/announcement/anouncement.json";
import AnnouncementDataUz from "../../../data/announcement/anouncement-uz.json";
import AnnouncementDataRu from "../../../data/announcement/anouncement-ru.json";
import { useLanguageDetector } from "../../../hooks/useLanguageDetector.js";
import { Col, Container, Row } from "react-bootstrap";
import { Logo } from "./Logo";

const WebMenu = () => {
  const [announcementData] = useLanguageDetector(
    AnnouncementData,
    AnnouncementDataRu,
    AnnouncementDataUz
  );

  console.log(announcementData);
  return (
    <div className="new-design">
      <AnnouncementBar data={{ ...announcementData[2] }} />
      <div className={`logo-area2 z-index-2`}>
        <Container className="position-relative">
          <Row className="align-items-center">
            <Col md="2">
              <Logo />
            </Col>
            <Col md="10" className="position-static">
              <HeaderLinks />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default WebMenu;
