import React from "react";
import HeaderDropdown from "./HeaderDropdown";
import HeaderItem from "./HeaderItem";
import { ConferencePageHeadings } from "../configs";

export const HeaderLinks = () => {
  return (
    <>
      <div className="menu-box d-flex justify-content-end">
        <ul className="nav menu-nav">
          <HeaderItem title={"Study"} link="/study" />
          <HeaderItem title={"Apply"} link="/apply-students" />
          <HeaderDropdown
            title={"Students"}
            navItems={[
              { title: "Student Life", link: "/student-life" },
              {
                title: "Student Support Service",
                link: "/student-support-service",
              },
              {
                title: "Student Information and Support Section – PTU Assists",
                link: "/student-information-support",
              },
              {
                title: "Social Engagement - Clubs",
                link: "/social",
              },
              {
                title: "International",
                link: "/international",
              },
              {
                title: "Tuition Fees",
                link: "/tuition-fees",
              },
              {
                title: "Academic Regulations",
                link: "/academic-regulations",
              },
            ]}
          />
          <HeaderItem title={"News"} link="/news" />
          <HeaderItem
            title={"Conference"}
            link="/conference"
            dropdownHeadings={ConferencePageHeadings}
          />
          <HeaderDropdown
            title={"About Us"}
            link="/about"
            navItems={[
              { title: "Strategy", link: "/strategy" },
              { title: "Partners", link: "/partners" },
              { title: "Our Team", link: "/team" },
              { title: "Vacancies", link: "/vacancies" },
              { title: "Contact Us", link: "/contact" },
            ]}
            dropdownRightAlign={true}
          />
        </ul>
      </div>
      <div className="d-none"></div>
    </>
  );
};
