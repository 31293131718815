import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .top-bar2 {
    height: 53px;
    background: ${colors.bg1};
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    transition: 10000ms;

    .row {
      div {
        display: flex;
        align-items: center;
      }
    }

    .bar-left {
      ul {
        li {
          font-size: 14px;
          color: ${colors.text6};
          margin-right: 20px;
          display: inline-block;

          i {
            font-size: 18px;
            color: ${colors.bg2};
            vertical-align: text-bottom;
            margin-right: 5px;

            @media (max-width: 1199px) {
              margin-right: 2px;
            }
          }

          a {
            font-size: 14px;
            color: ${colors.text6};

            &:hover {
              color: ${colors.bg2};
            }
          }

          @media (max-width: 1199px) {
            margin-right: 8px;
          }

          @media (max-width: 991px) {
            font-size: 13px;
          }
        }
      }
    }

    .bar-right {
      ul.bar-lang {
        margin-right: 30px;
        position: relative;
        margin-top: 3px;
        display: flex;

        &::before {
          position: absolute;
          content: "";
          background: ${colors.border1};
          width: 1px;
          height: 20px;
          top: 0;
          right: -15px;
        }

        li {
          .dropdown {
            button.dropdown-toggle {
              font-size: 13px;
              color: ${colors.text6};
              background: transparent;
              border: none;
              padding: 0;
              box-shadow: none;

              img {
                margin-right: 5px;
                max-width: 21px;
              }

              i {
                font-size: 12px;
                margin-left: 3px;
              }

              &::after {
                display: none;
              }

              &:hover {
                cursor: pointer;
                color: ${colors.bg2};
              }
            }

            ul.dropdown-menu {
              padding: 0;
              margin: 0;
              border: none;
              background-color: #ffffff;
              border-radius: 8px;
              top: 50% !important;
              left: -16px !important;
              box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

              li {
                display: block;
                /* border-bottom: 1px solid ${colors.border1}; */
                padding: 7px 15px;
                color: ${colors.black1};
                cursor: pointer;

                img {
                  max-width: 21px;
                  margin-right: 5px;
                }

                &:hover {
                  color: ${colors.bg2};
                  font-weight: 500;
                  background-color: transparent;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      ul.bar-social {
        position: relative;

        li {
          a {
            font-size: 13px;
            color: ${colors.bg1};
            background: ${colors.border1};
            display: inline-block;
            width: 25px;
            height: 25px;
            text-align: center;
            padding-top: 3px;
            border-radius: 8px;

            &:hover {
              background: ${colors.text6};
              color: ${colors.bg2};
            }
          }

          &:last-child {
            a {
              margin-right: 0;
            }
          }
        }

        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .new-design {
    position: absolute;
    left: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .logo-area2 {
    position: fixed;
    top: 99px;
    left: 0;
    width: 100%;
    z-index: 11;
    animation: 300ms ease-in-out 0s normal none 1 fadeInDown;
    background-color: transparent;
    height: 100px;
    display: felx;
    align-items: center;
    .logo {
      a {
        img {
          max-width: 171px;
          @media (max-width: 991px) {
            max-width: 171px;
          }
        }
      }
    }

    ul.nav.menu-nav {
      column-gap: 20px;

      @media (max-width: 1199px) {
        column-gap: 15px;
      }

      .nav-dropdown {
        position: relative;
      }

      li.nav-item {
        a.nav-link {
          font-size: 14px;
          color: ${colors.text6};
          text-transform: uppercase;
          font-weight: 500;
          padding: 0;

          i {
            font-size: 12px;
          }

          &:after {
            content: none;
          }

          &:hover {
            color: ${colors.bg2};
          }

          @media (max-width: 1199px) {
            letter-spacing: 0;
          }
        }

        a.nav-link.active {
          color: ${colors.bg2};
          font-weight: 600;
        }

        ul.dropdown {
          position: absolute;
          left: 0;
          right: 0;
          top: 35px;
          min-width: 400px;
          background: #fff;
          /* border: 1px solid ${colors.border1}; */
          text-align: left;
          padding: 0;
          border-radius: 8px;
          transition: 0.2s ease;
          opacity: 0;
          transform: scaleY(0);
          visibility: hidden;
          z-index: 2;
          transform-origin: center top 0;
          width: auto;

          li.nav-item {
            a.nav-link {
              position: relative;
              font-size: 14px;
              font-weight: 600;
              color: ${colors.text1};
              padding: 10px 20px;
              text-transform: capitalize;
              margin-right: 0;
              border-bottom: 1px solid ${colors.text5};

              // Add this new rule

              &:hover {
                color: ${colors.bg1};

                i {
                  color: #fff;
                }
              }

              i {
                float: right;
                margin-top: 4px;
              }
            }

            &:last-child {
              margin-left: 0;

              a.nav-link {
                border-bottom: none;
              }
            }

            ul.dropdown2 {
              position: absolute;
              top: 0;
              left: 100%;
              min-width: 180px;
              background: #fff;
              border: 1px solid ${colors.border1};
              transition: 0.2s ease;
              opacity: 0;
              transform: scaleY(0);
              visibility: hidden;
              z-index: 999;
              transform-origin: center top 0;
            }

            &:hover {
              ul.dropdown2 {
                opacity: 1;
                transform: scaleY(1);
                visibility: visible;
              }
            }
          }
        }

        &:hover {
          ul.dropdown {
            opacity: 1;
            transform: scaleY(1);
            visibility: visible;
          }
        }

        ul.dropdown-end {
          right: 0;
          left: auto;
          min-width: 140px;
        }

        .conference-header {
          position: absolute;
          left: 0;
          right: 0;
          top: 85px;
          min-width: 160px;
          text-align: left;
          border-radius: 8px;
          transition: 0.2s ease;
          opacity: 0;
          transform: scaleY(0);
          visibility: hidden;
          z-index: 999;
          transform-origin: center top 0;
          width: auto;

          /* li.nav-item {
              :last-child {
                a.nav-link::after {
                  content: none;
                }
              }

              a.nav-link {
                font-size: 14px;
                font-weight: 600;
                color: ${colors.text1};
                padding: 10px 20px;
                text-transform: capitalize;
                margin-right: 0;

                ::after {
                  content: "";
                  width: 100%;
                  height: 1px;
                  position: absolute;
                  background-color: ${colors.text5};
                  bottom: 0;
                  left: 0;
                }

                &:hover {
                  color: ${colors.bg1};

                  i {
                    color: #fff;
                  }
                }

                i {
                  float: right;
                  margin-top: 4px;
                }
              }

              &:last-child {
                margin-left: 0;

                a.nav-link {
                  border-bottom: none;
                }
              }

              ul.dropdown2 {
                position: absolute;
                top: 0;
                left: 100%;
                min-width: 180px;
                background: #fff;
                border: 1px solid ${colors.border1};
                transition: 0.2s ease;
                opacity: 0;
                transform: scaleY(0);
                visibility: hidden;
                z-index: 999;
                transform-origin: center top 0;
              }

              &:hover {
                ul.dropdown2 {
                  opacity: 1;
                  transform: scaleY(1);
                  visibility: visible;
                }
              }
            } */
        }

        &:hover {
          .conference-header {
            opacity: 1;
            transform: scaleY(1);
            visibility: visible;
          }
        }
      }
    }

    .search-box {
      margin-left: 18px;
      margin-top: 11px;

      a {
        padding: 0;

        i {
          font-size: 18px;
          color: ${colors.bg1};
        }
      }

      i.close-btn {
        position: absolute;
        top: 25px;
        right: -12px;
        font-size: 26px;
        color: ${colors.bg1};
        cursor: pointer;
      }

      @media (max-width: 1199px) {
        margin-left: 5px;
      }
    }

    .apply-btn {
      margin-left: 70px;
      margin-top: -1px;

      a {
        font-size: 13px;
        color: #ffffff;
        background: ${colors.gr_bg};
        display: inline-block;
        width: 110px;
        height: 40px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        padding: 11px;
        border-radius: 0 8px 8px 0;
        position: relative;

        i {
          position: absolute;
          font-size: 20px;
          left: -40px;
          top: 0;
          padding: 10px;
          width: 40px;
          height: 100%;
          border-radius: 8px 0 0 8px;
          background-color: ${colors.bg1};
        }

        &:hover {
          background: ${colors.gr_bg2};
        }
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .logo-area2.sticky {
    top: 0;
    background: rgba(102, 42, 48, 0.8);
    box-shadow: 0 8px 32px rgb(31 31 31 / 37%);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(7px);
    transition: all 0.5s linear;
  }
`;
