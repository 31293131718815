import styled from "styled-components";
import { colors } from "../../../../common/element/elements";

export const Styles = styled.div`
  .conference-header {
    display: flex;
    justify-content: center;
    height: 500px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    margin: 0 15px;
    background-image: url("/assets/images/download.jpg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-color: #eeeeee;

    .conference-header-item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      border-right: 1px solid ${colors.text4};
      padding: 30px 30px;
      text-align: start;

      .conference-header-item-content {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        row-gap: 10px;

        .conference-header-item-content-item-link {
          color: ${colors.text1};
          font-weight: 500;

          :hover {
            color: ${colors.bg1};
          }
        }
      }

      .conference-header-item-title {
        font-size: 18px;
        font-weight: 700;
        color: ${colors.bg1};
        text-align: start;
        position: relative;
      }
    }

    .logo-area {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      row-gap: 60px;
      padding: 0 20px;

      .logo {
        padding-top: 20px;
      }

      .conference-header-item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: center;
        row-gap: 10px;

        .conference-header-item-title {
          font-size: 16px;
          font-weight: 700;
          color: #000000;
          text-align: center;
        }

        .year {
          font-size: 26px;
          font-weight: 700;
          color: #000000;
        }

        .conference-header-item-subtitle {
          font-size: 14px;
          font-weight: 500;
          font-style: italic;
          color: ${colors.bg2};
          text-align: center;
          margin-top: 20px;
        }
      }
    }

    @media (min-width: 1200px) {
      width: 1140px;
    }

    @media (min-width: 992px) {
      width: 960px;
    }
    @media (min-width: 768px) {
      width: 720px;
    }
  }
`;
