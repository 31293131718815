import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { colors } from "../../../../components/common/element/elements";
import Heading from "../../../../components/shared/Heading";

export const InstructionSection = ({ data }) => {
  return (
    <section className="instruction" id="instruction">
      <Container>
        <Row>
          <Col md="12">
            <div className="main ">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Abstract Submission Instruction"}
              />
              <div className="instruction-list">
                {data.abstract_instruction.map((i) => (
                  <div className="instruction-item">
                    <div className="instruction-icon">
                      <Icon
                        icon="ph:warning-circle-duotone"
                        fontSize={24}
                        color={colors.bg2}
                        className="instruction-icon"
                      />
                    </div>
                    <div>
                      {i.text}{" "}
                      {i.link ? (
                        <a href={i.link.url} target="_blank">
                          {i.link.text}
                        </a>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
